<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
      <a class="navbar-brand js-scroll-trigger" href="#page-top">
                <span class="d-block d-lg-none">
                    <span class="">{{ firstName }}</span>
                    <span>{{ lastName }}</span>
                </span>
        <span class="d-none d-lg-block">
                    <img class="img-fluid img-profile rounded-circle mx-auto mb-2" src=".//image/michael-koert-portrait.jpg" alt="">
                </span>
      </a>
      <button class="navbar-toggler" id="navbar-toggler" type="button" data-toggle="collapse"
              data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" v-on:click="closeMenu()" href="#about">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" v-on:click="closeMenu()" href="#experience">Experience</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" v-on:click="closeMenu()" href="#education">Education</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" v-on:click="closeMenu()" href="#skills">Skills</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" v-on:click="closeMenu()" href="#technologies">Technologies</a>
          </li>

          <!-- <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#interests">Interests</a>
          </li> -->
          <!-- <li class="nav-item">
              <a class="nav-link js-scroll-trigger" href="#awards">Awards</a>
          </li> -->
        </ul>
      </div>
      <div class="d-none d-lg-block">
        <div class="badge ">
          <a href="http://bcert.me/sajxtnewh">
            <img class="img-fluid"
                 src="https://www.scrumalliance.org/ScrumRedesignDEVSite/media/ScrumAllianceMedia/Certification%20Badges/Official/SAI_BadgeSizes_DigitalBadging_CSM.png"/>
          </a>
        </div>
      </div>
    </nav>
    <div class="container-fluid p-0">
      <section id="about" class="">
        <div class="p-2 p-lg-3 d-flex d-column">
          <div class="">
            <h1 class="display-2"><span class="">{{ firstName }}</span> <span class="text-primary">{{ lastName }}</span>
            </h1>
            <h2>{{ title }}</h2>
            <p>{{ address }} · {{ phone }} · {{ email }}</p>
            <p>{{ about }}</p>
          </div>
        </div>
      </section>
      <hr class="m-0">
      <main id="wrapper" role="main">
        <section id="experience" class="resume p-2 p-lg-3 d-flex d-column">
          <div class="my-auto">

            <h2 class="mb-3">Experience</h2>

            <div class="d-flex flex-column flex-md-row mb-3" v-for="job in jobs">
              <div class="mr-auto">
                <div class="">
                  <h3>{{ job.title }}</h3>
                  <div class="row">
                    <div class="col-8">
                      <span class="subheading">{{ job.company }}</span>

                    </div>
                    <div class="col-4 text-right">
                      <span class="">{{ job.start }} - {{ job.finish }}</span>
                    </div>
                  </div>

                </div>

                <div class="">
                  <p>{{ job.description }}</p>
                </div>

              </div>
            </div>
          </div>
        </section>
        <hr class="m-0">
        <section id="education" class="resume p-2 p-lg-3 d-flex d-column">
          <div class="my-auto">

            <h2 class="mb-3">Education</h2>
            <div class="d-flex flex-column flex-md-row mb-3">

              <div class="row">
                <div class="col-12 " v-for="education in educations">
                  <h4>{{ education.school }} <span class="small">{{ education.completion }}</span></h4>
                  <p>{{ education.degree }}</p>
                </div>
              </div>

            </div>
          </div>
        </section>
        <hr class="m-0">
        <section id="skills" class="resume p-2 p-lg-3 d-flex d-column">
          <div class="my-auto">

            <h2 class="mb-3">Skills</h2>
            <div class="d-flex flex-column flex-md-row ">
              <div class="row">
                <div class="col-4 d-none d-print-inline" v-for="skill in skills">

                  <h6>{{ skill.name }}</h6>
                  <span v-for="(sub, index) in skill.subs">
                                        <span>{{ sub.name }}</span><span v-if="index+1 < skill.subs.length">, </span>
                                    </span>
                  <!-- <ul>
                      <li v-for="sub in skill.subs" >{{sub.name}}</li>
                  </ul> -->

                </div>
                <!-- <div class="col-12">
                    <ul>
                        <li v-for="skill in skills">
                            {{ skill.name }}
                            <ul>
                                <li v-for="sub in skill.subs" >{{sub.name}}</li>
                            </ul>
                        </li>
                    </ul>
                </div> -->
                <!-- <div class="card-deck"> -->
                <div class="col-sm-4 mt-2 d-block d-print-none" v-for="skill in skills">
                  <div class="card mx-2 mx-auto">
                    <div class="card-body">
                      <h5 class="card-title">{{ skill.name }}</h4>
                        <ul>
                          <li v-for="sub in skill.subs">{{ sub.name }}</li>
                        </ul>
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr class="m-0">
        <section id="technologies" class="resume d-print-none p-2 p-lg-3 d-flex d-column">
          <div class="my-auto">
            <h2 class="mb-3">Technologies</h2>
            <div class="d-flex flex-column flex-md-row ">
              <div class="row">
                <div class="col-sm-4 mt-2 d-block " v-for="technology in technologies">
                  <div class="card mx-2 mx-auto">
                    <div class="card-body">
                      <h5 class="card-title">{{ technology.name }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr class="m-0">
        <section id="certifications" class="resume d-print-none p-2 p-lg-3 d-flex d-column">
          <div class="my-auto">
            <h2 class="mb-3">Certifications</h2>
            <div class="d-flex flex-column flex-md-row ">
              <div class="row">
                <div class="col-sm-4 mt-2 d-block">
                  <div class="badge ">
                    <a href="http://bcert.me/sajxtnewh">
                      <img class="img-fluid"
                           src="https://www.scrumalliance.org/ScrumRedesignDEVSite/media/ScrumAllianceMedia/Certification%20Badges/Official/SAI_BadgeSizes_DigitalBadging_CSM.png"/>
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  name: "app",
  methods: {
    closeMenu() {
      let navBarTogglerId = "navbar-toggler";
      let navBarToggler = document.getElementById(navBarTogglerId);
      let navBarTogglerAriaExpanded = document
          .getElementById(navBarTogglerId)
          .getAttribute("aria-expanded");
      let navbarSupportedContent = document.getElementById(
          "navbarSupportedContent"
      );
      navbarSupportedContent.classList.add("collpasing");
      navbarSupportedContent.classList.remove("collpasing");
      navbarSupportedContent.classList.add("collapsed");
      navBarToggler.classList.add("collapsed");
      navbarSupportedContent.classList.remove("show");

      navBarTogglerAriaExpanded = false;
      document
          .getElementById(navBarTogglerId)
          .setAttribute("aria-expanded", navBarTogglerAriaExpanded);
    }
  },
  data() {
    return {
      firstName: "Michael",
      lastName: "Koert",
      title: "Software Architect and Product Development",
      phone: "(616) 402-1213",
      email: "michaelkoert@gmail.com",
      address: "Spring Lake, MI",
      social: {
        github: "https://github.com/mkoert",
      },
      about: "Accomplished Software Architect with extensive experience in SaaS solutions across diverse industries including medical, financial, and manufactured housing sectors. Proven expertise in leading cross-functional teams, managing complex projects, and delivering innovative solutions. Enthusiastic about woodworking, photography, and technology projects involving Arduino.",
      jobs: [
        {
          company: "Genesis Software Innovations",
          title: "Acting Director of Technology, Lead Software Architect, Software Architect",
          start: "Apr. 21",
          finish: "Present",
          description: `Spearhead the development and implementation of FDA 510k cleared software devices, overseeing a team of 8 to 18 professionals, including developers, QA testers, DevOps engineers, Scrum Masters, QMS leads, and AI specialists.
Direct project management activities, including budgeting, resource estimation, and staffing, ensuring alignment with organizational goals and project timelines.
Drive technological advancements and strategic initiatives to enhance software solutions and operational efficiency.`
        },
        {
          company:
              "Datacomp Appraisal Services Inc. and MHVillage Inc.",
          title: "Lead Developer, Senior Web Developer, Web Developer",
          start: "Feb. 15",
          finish: "Apr 21",
          description: `Revamped codebases utilizing contemporary coding standards, incorporating interfaces, traits, namespaces, and dependency injection to enhance application performance and maintainability.
Developed and implemented a custom MVC framework with advanced routing and configuration capabilities, alongside a RESTful API, to modernize application infrastructure.
Pioneered the integration of modern frontend frameworks and build processes, significantly improving development workflows and production deployment for the appraisal ordering application and MHVillage.com.`
        },
        {
          company: "ThinkLiquidity",
          title: "Web Developer",
          start: "Mar. 13",
          finish: "Jan. 15",
          description: `Designed and developed web-based applications using PHP, MySQL, Node.js, AngularJS, and Redis on CentOS servers for foreign currency brokers, enhancing analytics and risk management.
Created and optimized interfaces for brokers to manage accounts on MT4 Trade Servers, improving monitoring and account management efficiency.`
        },
        {
          company: "Wintrust Financial Corporation",
          title: "Senior Web Developer, Web Developer",
          start: "Jan. 09",
          finish: "Mar. 13",
          description: `Contributed to the redevelopment of over 67 bank websites using Drupal and Symfony, focusing on web development, design, and interactive elements.
Managed network and file storage systems for Macintosh users, ensuring data integrity and system reliability.`
        },
        {
          company: "Blue Bike Productions LLC",
          title: "Freelance Web Design and Development",
          start: "Jul. 07",
          finish: "Present",
          description: `Designed and developed a diverse range of websites for various industries, including hair salons, dog training services, and court reporting firms.
Provided tailored web solutions that meet client needs and enhance online presence.`
        }
      ],
      educations: [
        {
          school: "Full Sail University",
          degree: "Bachelor of Science in Digital Arts and Design",
          completion: "Jun. 2008"
        },
        // {
        //   school: "Spring Lake High School",
        //   degree: "High School Diploma",
        //   completion: "Feb. 2006"
        // }
      ],

      involvements: [
        {
          organization: "Harvest Bible Chapel Spring Lake",
          activity:
              "Weekend Producer, Small Group Leader, Photography, Video, Motion Graphics, Live Stream and Sound Engineer",
          start: "Mar. 13",
          finish: "Jun. 24"
        },
        // {
        //   organization: "Rogers Park Community Church",
        //   activity: "Creative Team Leader, Video and Sound Engineer",
        //   start: "Feb. 09",
        //   finish: "Feb. 13"
        // },
        // {
        //   organization: "Boy Scouts of America",
        //   activity: "Eagle Scout",
        //   start: "Jan. 99",
        //   finish: "Jan. 06"
        // }
      ],
      skills: [
        {
          name: "Domain Driven Development",
          subs: [
            {
              name: "DDD"
            },
            {
              name: "TDD"
            },
            // {
            //   name: "Cypress"
            // },
          ]
        },
        {
          name: "Java",
          subs: [
            {
              name: "Spring"
            },
          ]
        },
        {
          name: "Python",
          subs: [
            {
              name: "Django"
            },

          ]
        },
        {
          name: "PHP (8.0+)",
          subs: [
            {
              name: "Laravel 8+"
            },
            {
              name: "Symfony 6.5+"
            }
          ]
        },
        {
          name: "MySQL, MariaDb, Percona, Postgres",
          subs: [
            {
              name: "Master + Master Replication"
            },
            {
              name: "Multiple Slave Replication"
            }
          ]
        },
        {
          name: "Javascript (ES2020+, TS)",
          subs: [
            {
              name: "React"
            },
            {
              name: "Node"
            },
            {
              name: "Vue JS"
            },
            {
              name: "Angular JS"
            }
          ]
        },
        {
          name: "HTML5, CSS3, SASS",
          subs: []
        },
        {
          name: "GIT, SVN, Git CI/CD",
          subs: [{name: "Jenkins"}, {name:"Gitlab Pipelines"}]
        },
        {
          name: "Containerization",
          subs: [{name:"Docker"}, {name:"Kubernetes"}]
        },
        {
          name: "Web Design, UI & UX",
          subs: []
        }
      ],
      technologies: [
        {
          name: "OS: Linux, Mac OS X"
        },
        { name: "GCP, AWS"},
        {
          name: "Credit Cards: Stripe, Spreedly, Authorize.net, PCI Compliance"
        },
        {
          name: "Server Cache: Redis, Memcache"
        },
        {
          name: "Web Servers: Nginx, Apache, Traefik"
        },
        {
          name: "Build Tools: Webpack, Parcel, Gulp"
        },
        {
          name: "Arduinos, Raspberry PIs, Relays, Servos"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
@import "custom";
@import "mixins";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "global";
@import "nav";
@import "resume";

#app {
  // margin-top: 50px;
  // color: grey;
  // color: #56b983;
}
</style>
